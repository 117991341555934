import { commEventBus } from '../../main'
import marked from 'marked'
import DOMPurify from 'dompurify'

/*
    * resizeBasedOnSelector
    * Make every elements matching a selector the same height as the element of
    * the highest height.

    @param  : selector String
    @return : null
*/

function resizeBasedOnSelector(selector) {
  // Get elements to resize
  const nodelist = document.querySelectorAll(selector)
  // Turn it into a list
  const elList = []
  for (let i = 0; i < nodelist.length; i++) { elList.push(nodelist[i]) }
  // Get max height
  const maxHeight = Math.max.apply(null, elList.map(x => x.clientHeight))
  // Apply it to all elements
  if (maxHeight > 0) {
    for (let i = 0; i < nodelist.length; i++) { nodelist[i].style.height = `${maxHeight}px` }
  }
}

/*
    * daysBetweenTwoDates
    * Calculates the number of days between 2 given dates.

    @param  : dateStart date in the unix format
    @param  : dateEnd date in the unix format
    @return : int
*/
function daysBetweenTwoDates(dateStart, dateEnd) {
  const timeDifference = dateEnd > dateStart ? dateEnd - dateStart : dateStart - dateEnd
  return parseInt(timeDifference / (1000 * 3600 * 24))
}

/*
    * computeDateBarProgress
    * Returns the percentage of completion between a range of dates and now

    @param  : start Date object
    @param  : selector Date object
    @return : int
*/
function computeDateBarProgress(start, end) {
  if (!start || !end) {
    return 0
  }
  const dateStart = Date.parse(start)
  const dateEnd = Date.parse(end)
  const dateNow = Date.now()

  if (dateNow > dateEnd) {
    return 100
  } else if (dateNow < dateStart) {
    return 0
  } else {
    const campaignDays = daysBetweenTwoDates(dateStart, dateEnd)
    const passedCampaignDays = daysBetweenTwoDates(dateStart, dateNow)
    return parseInt(passedCampaignDays * 100 / campaignDays)
  }
}

/*
    * openLink
    * Opens a link in the same window or in a new tab

    @param  : link String link to be opened
    @param  : newTab boolean setting it to true will open the link in a new tab
    @return : null
*/
function openLink(link, newTab = false) {
  window.open(link, newTab ? '_blank' : '_self')
}

/*
    * ajaxCall
    * Encapsulates a traditional XMLHttpRequest asynchronous call

    @param  : method String 'POST', 'GET'
    @param  : endpoint String url to be called
    @param  : payload Object data to be passed to POST calls
    @param  : headers List headers to be passed to the call. Must be of shape: { 'k': 'key', 'v': 'value' }
    @return : Promise
*/
function ajaxCall(method, endpoint, payload, headers = [{ 'k': 'Content-Type', 'v': 'application/json' }]) {
  return new Promise((resolve, reject) => {
    const xhttp = new XMLHttpRequest()
    xhttp.onreadystatechange = function() {
      if (this.readyState === 4) {
        if (this.status === 200 || this.status === 201) {
          resolve(JSON.parse(this.response))
        } else {
          reject()
        }
      }
    }
    xhttp.open(method, endpoint, true)
    headers.forEach(h => {
      xhttp.setRequestHeader(h.k, h.v)
    })
    xhttp.send(JSON.stringify(payload))
  })
}

/*
    * formatDateDash
    * Formats a date to the following shape 'YYYY-MM-DD'

    @param  : d Date object
    @return : string
*/
function formatDateDash(d) {
  const month = d.getMonth() + 1
  const day = d.getDate()
  return d.getFullYear() + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + d.getDate()
}

/*
    * formatDateSlash
    * Formats a date to the following shape 'DD/MM/YYYY'

    @param  : d Date object
    @return : string
*/
function formatDateSlash(d, showtime = false, tz = 'UTC') {
  const formattedDate = d.toLocaleString('en-sg', { timeZone: tz }).replace(',', '')
  return showtime ? formattedDate : formattedDate.substring(0, 10)
}

/*
    * showSnackbar
    * Sends a `show-snackbar` event to the comm event bus

    @param  : message String message to show in the snackBar
    @return : null
*/
function showSnackbar(message) {
  commEventBus.$emit('show-snackbar', { message: message })
}

/*
    * showConfirmation
    * Sends a `show-confirmation` event to the comm event bus

    @param  : message String message to show in the snackBar
    @return : null
*/
function showConfirmation(title, message, link, callback = null) {
  commEventBus.$emit('show-confirmation', { message: message, link: link, title: title, callback: callback })
}

/*
    * copyToClipboard
    * Copies target of an element to the clipboard

    @param  : event event Object
    @return : null
*/
function copyToClipboard(event) {
  const t = event.target
  t.select()
  t.setSelectionRange(0, 99999)
  document.execCommand('copy')
}

/*
    * generatedTemporaryId
    * Generates a random int

    @param  : negative Boolean  specifies if output should be negative
    @return : int
*/
function generateRandomInt(negative = false) {
  return negative ? -Math.floor(Math.random() * 10000) : Math.floor(Math.random() * 10000)
}

/*
    * deepcopy
    * Returns a copy of the given object

    @param  : obj Object
    @return : Object
*/
function deepcopy(obj) {
  return JSON.parse(JSON.stringify(obj)) // Deepcopy
}

/*
    * falsyToNull
    * Normalizes all falsy values into null

    @param  : obj Object
    @return : Object or null
*/
function falsyToNull(obj) {
  return obj || null
}

/*
    * sanitizedMark
    * Turns Markdown into HTML and sanitize it

    @param  : String Markdown string
    @return : String HTML string
*/
function sanitizedMark(t) {
  return t ? DOMPurify.sanitize(marked(t), { FORBID_TAGS: ['a'] }) : null
}

const utils = {
  resizeBasedOnSelector,
  daysBetweenTwoDates,
  computeDateBarProgress,
  openLink,
  ajaxCall,
  formatDateDash,
  formatDateSlash,
  showSnackbar,
  showConfirmation,
  copyToClipboard,
  generateRandomInt,
  deepcopy,
  falsyToNull,
  sanitizedMark
}

export default utils
