<template>
  <div>
    <md-snackbar md-position="center" :md-duration="2000" :md-active.sync="show">
      <span>{{message}}</span>
    </md-snackbar>
  </div>
</template>

<script>
import { commEventBus } from '../../main'
export default {
  name: 'ae-snackbar',
  data: function() {
    return {
      message: '',
      show: false
    }
  },

  created: function() {
    commEventBus.$on('show-snackbar', (e) => {
      this.message = e.message
      this.show = true
    })
  }
}
</script>
