<template>
    <span>
        <md-field class="search-field">
            <!-- Search Icon -->
            <md-icon id="search-icon" tabindex="0" @mousedown.native.prevent="searchIconTapped" class="search-icon">search</md-icon>
            <!-- Input -->
            <md-input
                no-label-float
                id="global-search-input"
                ref="globalSearchInput"
                v-model="query"
                class="search-input"
                :class="{'search-input-active':toggleSearch}"
                autocorrect="off"
                placeholder="Search Campaign"
                @blur="onBlur"
                @keyup="onKeyUpInput"
            >
            </md-input>
            <!-- Clear Icon -->
            <md-icon id="clear-icon" v-show="toggleSearch" tabindex="0" @mousedown.native.prevent="resetData"  class="clear-icon">clear</md-icon>
        </md-field>

        <!-- Suggestions -->
        <md-list v-show="toggleSuggestions" class="suggestions-list md-double-line md-elevation-6">
            <md-list-item v-for="s in suggestions" v-bind:key="s.id" class="suggestions-list-item">
                <div @mousedown.prevent="onSuggestionClick(s.name)" class="md-list-item-text suggestions-list-item-text">
                    <span >{{s.name}}</span>
                    <span class="secondary">{{computeCampaignStatus(s.start, s.end)}}</span>
                </div>
                <md-button @mousedown.prevent="openCampaignPage(s.id)" class="md-icon-button md-list-action">
                    <md-icon>open_in_browser</md-icon>
                </md-button>
            </md-list-item>
            <md-list-item v-if="suggestions.length === 0" @mousedown.prevent="onSuggestionClick(query)" class="suggestions-list-item"><strong>{{query}}</strong></md-list-item>
        </md-list>
    </span>
</template>

<script>
import { eventBus } from '../../main'
import ls from '../../assets/js/localStorage'
import utils from '../../assets/js/utils'
export default {
  name: 'ae-base-search',
  data: function() {
    return {
      toggleSearch: false,
      query: '',
      suggestions: [],
      toggleSuggestions: false,
      localStorageFilters: {},
      localStorageKey: 'searchFilters',
      localStorageEnabled: null
    }
  },

  props: {
    searchUrl: { // Url of the search result page
      type: String,
      required: true
    },
    esNameUrl: { // Url of the ES name suggestion API
      type: String,
      required: true
    },
    campaignUrl: { // Url of one campaign. Use for campaign direct links.
      type: String,
      required: true
    },
    isSearchPage: { // Url of one campaign. Use for campaign direct links.
      type: Boolean,
      default: false
    }
  },

  watch: {
    query: function(query) {
      if (query) {
        const endpoint = this.esNameUrl + encodeURI(`?q=${this.query}`)
        utils.ajaxCall('GET', endpoint)
          .then(response => { this.suggestions = response })
      }
    }
  },

  mounted: function() {
    if (this.isSearchPage) { this.showSearch() }
    [this.localStorageEnabled, this.localStorageFilters] = ls.initializeLocalStorage(
      this.localStorageKey,
      this.isPersistantLocalStorage
    )
    this.setLocalFiltersFromLocalStorage()
    if (this.isSearchPage) {
      this.emitQueryUpdateEvent()
    } else {
      this.query = ''
      this.storeQueryToLocalStorage()
    }
  },

  methods: {
    /*
        * startSearch
        * Stores query to local storage and either open the search page
        or triggers search event.

        @param  : null
        @return : null
    */
    startSearch: function() {
      this.storeQueryToLocalStorage()
      if (this.isSearchPage) {
        this.emitQueryUpdateEvent()
      } else {
        window.open(this.searchUrl, '_self')
      }
    },
    /*
        * setLocalFiltersFromLocalStorage
        * Loads this.localStorageFilters query value into this.query variable

        @param  : null
        @return : null
    */
    setLocalFiltersFromLocalStorage: function() {
      if (this.localStorageFilters && this.localStorageFilters['q']) { this.query = this.localStorageFilters['q'] }
    },
    /*
        * storeQueryToLocalStorage
        * Sets the localstorage with query value, and resets page to 1

        @param  : null
        @return : null
    */
    storeQueryToLocalStorage: function() {
      const localStorageFilters = JSON.parse(localStorage.getItem(this.localStorageKey))
      localStorageFilters['q'] = this.query
      localStorageFilters['page'] = 1
      localStorage.setItem(this.localStorageKey, JSON.stringify(localStorageFilters))
    },
    /*
        * emitQueryUpdateEvent
        * Fires a 'search-query-updated' event, bound to be received by the card panel in order to update the search results.

        @param  : null
        @return : null
    */
    emitQueryUpdateEvent: function() {
      eventBus.$emit('search-query-updated', { 'query': this.query })
    },
    /*
        * openCampaignPage
        * Redirects to a campaign page given its campaign id

        @param  : id string of campaign id
        @return : null
    */
    openCampaignPage: function(id) {
      const url = this.campaignUrl.replace('10101', id)
      window.open(url, '_self')
    },
    /*
        * searchIconTapped
        * Event function called on tapping the search icon.
        Shows search input if hidden, or triggers search otherwise

        @param  : null
        @return : null
    */
    searchIconTapped: function() {
      if (!this.toggleSearch) {
        this.showSearch()
        this.$refs.globalSearchInput.$el.focus()
      } else {
        if (this.query !== '') {
          this.startSearch()
          this.hideSuggestions()
        }
      }
    },
    /*
        * onBlur
        * Event function called on losing focus of the search input field.
        Hides search input field and suggestions.

        @param  : e event object
        @return : null
    */
    onBlur: function() {
      this.hideSearch()
      this.hideSuggestions()
    },
    /*
        * onKeyUpInput
        * Event function called on key up in the search field.
        Will process suggestions on typing and call the search() function on pressing 'Enter' key.

        @param  : e event object
        @return : null
    */
    onKeyUpInput: function(e) {
      if (this.toggleSearch) {
        if (this.query === '') {
          this.hideSuggestions()
        } else {
          this.showSuggestions()
        }
        if (e.key === 'Enter') {
          this.hideSuggestions()
          this.startSearch()
        }
      }
    },
    /*
        * showSearch
        * Sets the toggleSearch variable to true and displays the input field

        @param  : null
        @return : null
    */
    showSearch: function() {
      this.toggleSearch = true

      if (this.query !== '') {
        this.showSuggestions()
      }
    },
    /*
        * hideSearch
        * Sets the toggleSearch variable to false and hides the input field

        @param  : null
        @return : null
    */
    hideSearch: function() {
      if (!this.isSearchPage) {
        this.toggleSearch = false
      }
    },
    /*
        * showSuggestions
        * Sets the toggleSuggestions variable to true

        @param  : null
        @return : null
    */
    showSuggestions: function() {
      this.toggleSuggestions = true
    },
    onSuggestionClick: function(name) {
      this.query = name
      this.hideSuggestions()
      this.startSearch()
    },
    /*
        * hideSuggestions
        * Sets the toggleSuggestions variable to false

        @param  : null
        @return : null
    */
    hideSuggestions: function() {
      this.toggleSuggestions = false
    },
    /*
        * resetData
        * Empties the input field and the query variable, and gives focus to the input field, and calls the search() function.

        @param  : null
        @return : null
    */
    resetData: function() {
      this.query = ''
      this.hideSuggestions()
      if (this.isSearchPage) {
        this.$refs.globalSearchInput.$el.focus()
        this.startSearch()
      }
    },
    /*
        * computeCampaignStatus
        * Empties the input field and the query variable, and gives focus to the input field, and calls the search() function.

        @param  : start ts of start of campaign
        @param  : end ts of end of campaign
        @return : string status of the campaign
    */
    computeCampaignStatus: function(start, end) {
      if (start === undefined || end === undefined) {
        return ''
      }
      const currentDate = new Date()
      const startDate = Date.parse(start)
      const endDate = Date.parse(end)

      if (currentDate > endDate) { return 'ENDED' }
      if (currentDate < startDate) {
        const daysDifference = utils.daysBetweenTwoDates(currentDate, startDate)
        return daysDifference > 31 ? 'STARTS IN >1 MONTH' : `STARTS IN ${daysDifference + 1} DAYS`
      }
      return 'RUNNING'
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "src/assets/css/mixins";

    .search-field{
        width: 330px;
        padding-top:0px;
        padding-bottom:0px;
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .search-icon{
        color: white;
        outline: 0;
        cursor : pointer;
        margin-right: 0px;
    }

    .clear-icon{
        color: white;
        outline: 0;
        margin-right: 0px;
        cursor : pointer;
    }

    input[type=text].search-input{
        color: white;
    }

    .search-input{
        max-width: 0px;
        @include transition (max-width 0.3s)
    }

    .search-input-active{
        max-width: 330px;
    }

    .search-input::placeholder{
        color: rgba(204, 204, 204, 0.54);
    }

    #global-search-input{
        border-bottom: rgba(204, 204, 204, 0.54) 1px solid;
    }

    .suggestions-list{
        position: absolute;
        z-index: 20;
        background-color: white;
        width: 330px;
    }

    .suggestions-list-item:hover{
      background-color: #F1F1F1
    }

    .suggestions-list-item-text{
        cursor: pointer;
         --md-theme-default-text-accent-on-background: var(--md-theme-default-accent);
    }
</style>
