<template>
  <div>
    <md-dialog :md-active.sync="show">
      <md-dialog-title v-if="title">{{title}}</md-dialog-title>
      <md-dialog-content>{{message}}</md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-primary" @click="show = false">Cancel</md-button>
        <md-button v-if="link" class="md-primary" :href="link">Confirm</md-button>
        <md-button v-else-if="callback" class="md-primary" @click="wrappedCallback()">Confirm</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

<script>
import { commEventBus } from '../../main'
export default {
  name: 'ae-confirmation-popup',
  data: function() {
    return {
      message: 'Are you sure you want to proceed?',
      title: null,
      link: '',
      callback: null,
      show: false
    }
  },

  created: function() {
    commEventBus.$on('show-confirmation', (e) => {
      if (e.title) { this.title = e.title }
      if (e.message) { this.message = e.message }
      if (e.link) { this.link = e.link }
      if (e.callback) { this.callback = e.callback }
      this.show = true
    })
  },

  methods: {
    wrappedCallback: function() {
      this.callback()
      this.show = false
    }
  }
}
</script>
