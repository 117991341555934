
/*
    * initializeLocalStorage
    * If the localstorage is empty, it is initialized with empty dictionary.
    Stores the value of localstorage in this.localStorageFilters

    @param  : localStorageKey string Key to be looked up in the local storage
    @param  : isPersistantLocalStorage boolean
    @param  : isNotPersistantLocalStorageCallback function Called if the localStorage is enabled but not persistant
    @return : localStorageEnabled boolean returns if localStorage is usable on this browser or not
    @return : localStorageFilters object Value stored in the localStorage at localStorageKey
*/
function initializeLocalStorage(localStorageKey, isPersistantLocalStorage = false, isNotPersistantLocalStorageCallback = () => {}) {
  let localStorageEnabled, localStorageFilters
  try {
    const test = 't'
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    localStorageEnabled = true
  } catch (e) {
    localStorageEnabled = false
  }

  if (localStorageEnabled) {
    if (localStorage.getItem(localStorageKey) === null) {
      localStorage.setItem(localStorageKey, JSON.stringify({}))
      localStorageFilters = {}
    } else {
      if (!isPersistantLocalStorage) {
        isNotPersistantLocalStorageCallback()
      }
      localStorageFilters = JSON.parse(localStorage.getItem(localStorageKey))
    }
  }

  return [localStorageEnabled, localStorageFilters]
}

const ls = {
  initializeLocalStorage
}

export default ls
