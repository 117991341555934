<template>
  <div class="row resize nav">
    <div class="col-md-12"><hr>
        <dt> <i class="fa fa-filter fa-fw fa-lg"></i> {{filtersTitle}} </dt>
        <br/>
    </div>

    <div class="filter-title" v-if="hasSearchIn">Search In</div>
    <div class="col-md-12 md-layout" v-if="hasSearchIn">
        <div class="md-layout-item" v-for="x in searchIn.all" :key="x.id">
            <md-checkbox class="checkbox" :model="filteredSearchIn" :value="x.id">
                <span class="filter-label">{{x.short_name}}</span>
            </md-checkbox>
        </div>
    </div>

    <div class="filter-title" v-if="parsedRegions">Regions</div>
    <div class="col-md-12 md-layout" v-if="parsedRegions">
        <div class="md-layout-item" v-for="x in parsedRegions.all" :key="x.id">
            <md-checkbox class="checkbox" :model="filteredRegions" :value="x.id">
                <span class="filter-label">{{x.short_name}}</span>
            </md-checkbox>
        </div>
    </div>

    <div class="filter-title" v-if="hasCreatedAt">Creation date</div>
    <div class="col-md-12 md-layout" v-if="hasCreatedAt">
        <div class="md-layout-item" >
            <md-datepicker
                v-model="filteredCreatedAtStart"
                :md-model-type="String"
                md-immediately
                >
                <label class="filter-label">Between</label>
            </md-datepicker>

            <md-datepicker
                v-model="filteredCreatedAtEnd"
                :md-model-type="String"
                :md-disabled-dates="filterCreatedAtEndDisabled"
                md-immediately>
                <label class="filter-label">And</label>
            </md-datepicker>
        </div>
    </div>

    <div class="filter-title" v-if="hasRunAt">Run date</div>
    <div class="col-md-12 md-layout" v-if="hasRunAt">
        <div class="md-layout-item" >
            <md-datepicker
                v-model="filteredRunAtStart"
                :md-model-type="String"
                md-immediately
                >
                <label class="filter-label">Between</label>
            </md-datepicker>

            <md-datepicker
                v-model="filteredRunAtEnd"
                :md-model-type="String"
                :md-disabled-dates="filterRunAtEndDisabled"
                md-immediately>
                <label class="filter-label">And</label>
            </md-datepicker>
        </div>
    </div>

    <div class="filter-title" v-if="parsedPlatforms">Platforms</div>
    <div class="col-md-12 md-layout" v-if="parsedPlatforms">
        <div class="md-layout-item" v-for="x in parsedPlatforms.all" :key="x.id">
            <md-checkbox class="checkbox" :model="filteredPlatforms" :value="x.id">
                <span class="filter-label">{{x.name}}</span>
            </md-checkbox>
        </div>
    </div>

    <div class="filter-title" v-if="parsedUsers">Created by</div>
    <div class="col-md-12" v-if="parsedUsers">
        <md-autocomplete
            v-model="filteredUser"
            :md-options="parsedUsers.all.map(x=>{return x.name})"
            :md-fuzzy-search="false"
            md-dense
            class="ae-md-field">
            <label class="filter-label">Email address</label>
            <template  slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text id="user-list" :md-term="term">{{ item }}</md-highlight-text>
            </template>
        </md-autocomplete>
    </div>

    <div class="filter-title" v-if="types">Categories</div>
    <div class="col-md-12" v-if="types">
        <md-autocomplete
            v-model="filteredCampaignType"
            :md-options="parsedTypes.campaign.all.map(x=>{return x.name})"
            md-dense
            :md-fuzzy-search="false"
            class="ae-md-field">
            <label class="filter-label">Campaign Type</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
            </template>
        </md-autocomplete>

        <md-autocomplete
            v-model="filteredMechanismType"
            :md-options="allMechanismTypes.map(x=>{return x.name})"
            md-dense
            :md-fuzzy-search="false"
            class="ae-md-field">
            <label class="filter-label">Mechanism Type</label>
            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                <md-highlight-text :md-term="term">{{ item }}</md-highlight-text>
            </template>
        </md-autocomplete>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../main'
import ls from '../../assets/js/localStorage'
export default {
  name: 'ae-filters-nav',

  data: function() {
    return {
      searchIn: JSON.parse(`{"all": [
                    { "id": "0", "short_name": "Title", "detail": "short_name" },      
                    { "id": "3", "short_name": "Id", "detail": "id" },
                    { "id": "4", "short_name": "Tags", "detail": "tag_string" }
                ], "filtered": []}`),
      filteredSearchIn: [],
      filteredRegions: [],
      filteredPlatforms: [],
      filteredCreatedAtStart: null,
      filteredCreatedAtEnd: null,
      filteredRunAtStart: null,
      filteredRunAtEnd: null,
      filteredUser: '',
      filteredCampaignType: '',
      filteredCampaignTypeId: null,
      filteredMechanismType: '',
      filteredMechanismTypeId: null,
      allMechanismTypes: [],
      persistantLocalStorageKeyList: ['globalFilters'],
      localStorageEnabled: null
    }
  },

  props: {
    pageType: { // Type of filters (search result filter, campaign filter, ...), to be displayed in the UI
      type: String,
      required: false
    },
    localStorageKey: { // Key of the local storage to get/set the filters from/to.
      type: String,
      default: 'globalFilters'
    },
    hasSearchIn: { // Boolean stating if the filter Search In should be displayed or not
      type: Boolean,
      default: false
    },
    regions: { // Object under the following format :{all: [list of all the regions], filtered: []}  containing the regions filter data
      type: String,
      required: false
    },
    platforms: { // Object under the following format :{all: [list of all the platforms], filtered: []}  containing the platforms filter data
      type: String,
      required: false
    },
    users: { // Object under the following format :{all: [list of all the users], filtered: []} containing the created by filter data
      type: String,
      required: false
    },
    types: { // Object under the following format :{campaign: {selected: "", all: [list of all campaign types]}, mechanism: {selected: "", tree: [tree of all mechanism types]}} containing the types filter data
      type: String,
      required: false
    },
    hasCreatedAt: { // Boolean stating if the filter Created At should be displayed or not
      type: Boolean,
      required: false
    },
    hasRunAt: { // Boolean stating if the filter Run At should be displayed or not
      type: Boolean,
      required: false
    }
  },

  computed: {
    filtersTitle: function() {
      const titleMapping = {
        'search': 'Search filters'
      }
      return titleMapping[this.pageType] || 'Global filters'
    },
    filterCreatedAtEndDisabled: function() {
      const that = this
      return function(d) {
        if (!that.filteredCreatedAtStart) { return false } else {
          const dateStart = Date.parse(that.filteredCreatedAtStart)
          return d < dateStart
        }
      }
    },
    filterRunAtEndDisabled: function() {
      const that = this
      return function(d) {
        if (!that.filteredRunAtStart) { return false } else {
          const dateStart = Date.parse(that.filteredRunAtStart)
          return d < dateStart
        }
      }
    },
    parsedPlatforms: function() {
      if (this.platforms) {
        const pp = JSON.parse(this.platforms)
        pp.all.push({ id: -1, name: 'Omni', description: null, is_active: true })
        return pp
      } else {
        return undefined
      }
    },
    parsedRegions: function() { return this.regions ? JSON.parse(this.regions) : undefined },
    parsedUsers: function() { return this.users ? JSON.parse(this.users) : undefined },
    parsedTypes: function() { return this.types ? JSON.parse(this.types) : undefined },
    isPersistantLocalStorage: function() {
      return this.persistantLocalStorageKeyList.indexOf(this.localStorageKey) >= 0
    }
  },

  watch: {
    filteredRegions: function() { this.setLocalStorageAndSendUpdateEvent() },
    filteredPlatforms: function() { this.setLocalStorageAndSendUpdateEvent() },
    filteredSearchIn: function() { this.setLocalStorageAndSendUpdateEvent() },
    filteredCreatedAtEnd: function() { this.setLocalStorageAndSendUpdateEvent() },
    filteredRunAtEnd: function() { this.setLocalStorageAndSendUpdateEvent() },
    filteredUser: function() { this.setLocalStorageAndSendUpdateEvent() },
    filteredCreatedAtStart: function() {
      if (this.filteredCreatedAtEnd < this.filteredCreatedAtStart) {
        this.filteredCreatedAtEnd = this.filteredCreatedAtStart
      }
      this.setLocalStorageAndSendUpdateEvent()
    },
    filteredRunAtStart: function() {
      if (this.filteredRunAtEnd < this.filteredRunAtStart) {
        this.filteredRunAtEnd = this.filteredRunAtStart
      }
      this.setLocalStorageAndSendUpdateEvent()
    },
    filteredCampaignType: function(v) {
      if (v) {
        const campaignTypeData = this.parsedTypes.campaign.all.filter(function(x) { return x.name === v })[0]
        this.filteredCampaignTypeId = campaignTypeData.id
        this.allMechanismTypes = this.parsedTypes.mechanism.tree[campaignTypeData.id]
      } else {
        this.filteredCampaignTypeId = null
        this.allMechanismTypes = []
      }
      this.filteredMechanismTypeId = null
      this.filteredMechanismType = ''

      this.setLocalStorageAndSendUpdateEvent()
    },
    filteredMechanismType: function(v) {
      if (this.filteredCampaignTypeId && this.filteredMechanismType) {
        const mechanismTypeData = this.parsedTypes.mechanism.tree[this.filteredCampaignTypeId].filter(function(x) { return x.name === v })[0]
        this.filteredMechanismTypeId = mechanismTypeData.id
      } else {
        this.filteredMechanismTypeId = null
      }
      this.setLocalStorageAndSendUpdateEvent()
    }
  },

  mounted: function() {
    this.initializeLocalFilters();
    [this.localStorageEnabled, this.localStorageFilters] = ls.initializeLocalStorage(
      this.localStorageKey,
      this.isPersistantLocalStorage,
      this.setLocalStorageFromLocalFilters
    )
    this.setLocalFiltersFromLocalStorage()
    this.sendFiltersUpdateEvent()
  },

  methods: {
    /*
        * initializeLocalFilters
        * initializes filtered* variables with server-provided values if any

        @param  : null
        @return : null
    */
    initializeLocalFilters: function() {
      this.filteredSearchIn = this.searchIn && this.searchIn.filtered ? this.searchIn.filtered : this.filteredSearchIn
      this.filteredRegions = this.regions && this.regions.filtered ? this.regions.filtered : this.filteredRegions
      this.filteredPlatforms = this.platforms && this.platforms.filtered ? this.platforms.filtered : this.filteredPlatforms
      this.filteredUser = this.users && this.users.filtered ? this.users.filtered : this.filteredUser
    },
    /*
        * setLocalFiltersFromLocalStorage
        * Loads this.localStorageFilters value into filtered* variables

        * Current setup doesn't allow campaign and mechanism type to be loaded from local storage
        which is alright because it currently is only used for non persistant search

        @param  : null
        @return : null
    */
    setLocalFiltersFromLocalStorage: function() {
      if (this.hasSearchIn && this.localStorageFilters['searchIn']) { this.filteredSearchIn = this.localStorageFilters['searchIn'] }
      if (this.regions && this.localStorageFilters['regions']) { this.filteredRegions = this.localStorageFilters['regions'] }
      if (this.platforms && this.localStorageFilters['platforms']) { this.filteredPlatforms = this.localStorageFilters['platforms'] }
      if (this.hasCreatedAt && this.localStorageFilters['createdAtDateStart']) { this.filteredCreatedAtStart = this.localStorageFilters['createdAtDateStart'] }
      if (this.hasCreatedAt && this.localStorageFilters['createdAtDateEnd']) { this.filteredCreatedAtEnd = this.localStorageFilters['createdAtDateEnd'] }
      if (this.hasRunAt && this.localStorageFilters['runAtDateStart']) { this.filteredRunAtStart = this.localStorageFilters['runAtDateStart'] }
      if (this.hasRunAt && this.localStorageFilters['runAtDateEnd']) { this.filteredRunAtEnd = this.localStorageFilters['runAtDateEnd'] }
      if (this.users && this.localStorageFilters['users']) { this.filteredUser = this.localStorageFilters['users'] }
    },
    /*
        * setLocalFiltersFromLocalStorage
        * Loads filtered* variables into local storage

        @param  : null
        @return : null
    */
    setLocalStorageFromLocalFilters: function() {
      const filters = JSON.parse(localStorage.getItem(this.localStorageKey))
      filters['searchIn'] = this.searchIn ? this.filteredSearchIn : []
      filters['regions'] = this.regions ? this.filteredRegions : []
      filters['platforms'] = this.platforms ? this.filteredPlatforms : []
      filters['createdAtDateStart'] = this.hasCreatedAt ? this.filteredCreatedAtStart : ''
      filters['createdAtDateEnd'] = this.hasCreatedAt ? this.filteredCreatedAtEnd : ''
      filters['runAtDateStart'] = this.hasRunAt ? this.filteredRunAtStart : ''
      filters['runAtDateEnd'] = this.hasRunAt ? this.filteredRunAtEnd : ''
      filters['users'] = this.users ? this.filteredUser : []
      filters['campaignType'] = this.types ? this.filteredCampaignTypeId : null
      filters['mechanismType'] = this.types ? this.filteredMechanismTypeId : null

      localStorage.setItem(this.localStorageKey, JSON.stringify(filters))
    },
    /*
        * sendFiltersUpdateEvent
        * Fires an event to be passed to DOM

        @param  : null
        @return : null
    */
    sendFiltersUpdateEvent: function() {
      eventBus.$emit('filters-updated', { 'localStorageKey': this.localStorageKey })
    },
    setLocalStorageAndSendUpdateEvent: function() {
      this.setLocalStorageFromLocalFilters()
      this.sendFiltersUpdateEvent()
    }
  }
}
</script>

<style lang="css">
  .ae-md-field input {
    margin: 0px;
    border-bottom: 1px solid #ddd !important; /* required otherwise it gets overwritten by theme */
  }
</style>

<style lang="scss" scoped>
  .nav {
      width:90%;
      margin:auto;
  }

  .filter-title {
      font-weight: bold;
      padding-left: 15px;
      color: #444444
  }

  .checkbox {
      margin:12px 12px 12px 0px;
  }

  .filter-label {
      font-weight: initial;
      color: #717171
  }

  .md-layout {
      padding-bottom: 10px;
  }

  .md-layout-item .md-field {
      margin-bottom: 0px;
  }
</style>
