// JS
import Vue from 'vue/dist/vue.js'
import {
  MdIcon, MdField, MdList, MdButton, MdCheckbox,
  MdAutocomplete, MdDatepicker, MdMenu, MdDialog, MdHighlightText,
  MdCard, MdRipple, MdProgress, MdTooltip, MdTable, MdContent,
  MdChips, MdSnackbar, MdTabs, MdSwitch, MdDialogPrompt
} from 'vue-material/dist/components'
import VueObserveVisibility from 'vue-observe-visibility'
import '@/assets/js/main.legacy'

// CSS
import 'vue-material/dist/vue-material.min.css'
import '@/assets/css/main.legacy.scss'
import '@/assets/css/main.scss'

// IMG
import mainLogo from '@/assets/img/logo_500x150.png'

// Synchronously loaded components
import AeBaseSearch from './components/base/ae-base-search.vue'
import AeFiltersNav from './components/base/ae-filters-nav.vue'
import AeSnackbar from './components/base/ae-snackbar.vue'
import AeConfirmationPopup from './components/base/ae-confirmation-popup.vue'

// Vue
Vue.use(MdIcon)
Vue.use(MdField)
Vue.use(MdList)
Vue.use(MdButton)
Vue.use(MdCheckbox)
Vue.use(MdAutocomplete)
Vue.use(MdDatepicker)
Vue.use(MdMenu)
Vue.use(MdDialog)
Vue.use(MdHighlightText)
Vue.use(MdCard)
Vue.use(MdRipple)
Vue.use(MdProgress)
Vue.use(MdTooltip)
Vue.use(MdTable)
Vue.use(MdContent)
Vue.use(MdChips)
Vue.use(MdSnackbar)
Vue.use(MdTabs)
Vue.use(MdSwitch)
Vue.use(MdDialogPrompt)
Vue.use(VueObserveVisibility)

Vue.config.productionTip = false

export const eventBus = new Vue()
export const commEventBus = new Vue()

new Vue({
  delimiters: ['[[', ']]'],
  el: '#aero-app',
  data: function() {
    return {
      img: {
        mainLogo: mainLogo
      }
    }
  },
  components: {
    AeBaseSearch: AeBaseSearch,
    AeFiltersNav: AeFiltersNav,
    AeSnackbar: AeSnackbar,
    AeConfirmationPopup: AeConfirmationPopup,
    AeCampaignList: () => import('./components/base/ae-campaign-list.vue'),
    AeCampaignPage: () => import('./components/campaign/ae-campaign-page.vue'),
    AeNewCampaign: () => import('./components/campaign/ae-new-campaign.vue'),
    AeCampaignActivity: () => import('./components/campaign/ae-campaign-activity.vue'),
    AeAdPage: () => import('./components/ads/ae-ad-page.vue'),
    AeAdsPage: () => import('./components/ads/ae-ads-page.vue'),
    AeAdMediaCards: () => import('./components/ads/ae-ad-media-cards.vue'),
    AeAdminSettings: () => import('./components/base/ae-admin-settings.vue')
  }
})
