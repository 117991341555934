import $ from 'jquery'

var menu = {
  show: function() {
    $('#col-nav-mobile').animate({
      left: 0
    }, 500)
    $('#col-nav-mobile').click(menu.hide)
    $('#col-nav-mobile #nav').click(function(event) {
      event.stopPropagation()
    })
  },
  hide: function() {
    $('#col-nav-mobile').animate({
      left: '-100%'
    }, 500)
  }
}
$('#navMobile').click(menu.show)

function enableNavMenu() {
  const menuItems = document.querySelectorAll('.menu > dl > dt')
  menuItems.forEach(node => {
    node.addEventListener('click', function() {
      const name = node.dataset.link
      const menuSubItem = document.querySelector(`dd.${name}`)
      const classListArray = [...menuSubItem.classList]
      classListArray.includes('active') ? menuSubItem.classList.remove('active') : menuSubItem.classList.add('active')
    })
  })
}
$(document).ready(function() {
  enableNavMenu()
})
